<template>
  <v-container fluid>
    <!---BOC:[breadcrumb]-->
    <ABreadcrumb :items="breadcrumb.items" />
    <!--EOC-->
    <!---BOC:[header]-->
    <BreadHeader :title="`Delete ${modelName}`"></BreadHeader>
    <!--EOC-->
    <!---BOC:[error]-->
    <AError :api="api" />
    <!--EOC-->
    <!---BOC:[form]-->
    <v-card>
      <v-card-text>
        <v-alert type="error"> This action is irreversible. </v-alert>
        <span
          >Are you sure you want to delete <b>{{ `${modelName}` }}</b
          >?</span
        >
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="$router.go(-1)"> Cancel </v-btn>
        <v-btn :isLoading="api.isLoading" color="error" @click="api.fetch()">
          Delete
        </v-btn>
      </v-card-actions>
    </v-card>
    <!--EOC-->
    <!---BOC:[note]-->
    <ADevNote
      :text="[
        '✗ Validate authority',
        '✗ Validate ownership',
        '✓ Show breadcrumbs',
        '✓ Show title',
        '✗ Run Read API',
        '✗ Show Read API Loading',
        '✗ Show Read API Error',
        '✗ Replace model item name',
        '✓ Show warning',
        '✓ Show Form Button isLoading',
        '✓ Run Action API',
        '✓ Show Action API Loading',
        '✓ Show Action API Error',
        '✗ Show snackbar',
      ]"
    />
    <!--EOC-->
  </v-container>
</template>

<script>
//BOC:[api]
import Api from "@/objects/api";
//EOC
//BOC:[breadcrumb]
import Breadcrumb from "@/objects/breadcrumb";
//EOC
//BOC:[model]
import Model from "@/objects/model";
//EOC
//BOC:[service]
import Service from "@/objects/service";
//EOC
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    services: (state) => state.service.data.services,
  }),
  props: {
    //
  },
  data: () => ({
    //BOC:[api]
    api: new Api(),
    //EOC
    //BOC:[breadcrumbs]
    breadcrumb: new Breadcrumb(),
    //EOC
    //BOC:[model]
    model: new Model(),
    modelId: null,
    modelName: null,
    //EOC
    //BOC:[service]
    service: new Service(),
    //EOC
  }),
  created() {
    //BOC:[model]
    this.model.getByKey(this.$route.params.modelKey);
    this.modelId = this.$route.params.modelId;
    this.modelName = `${this.model.name.singular} ${this.modelId}`;
    //EOC
    //BOC:[service]
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);
    //EOC
    //BOC:[breadcrumbs]
    this.breadcrumb.addForOperationDelete({
      services: this.services,
      model: this.model,
      modelId: this.modelId,
    });
    //EOC
    //BOC:[api]
    this.api.setMethod(`POST`);
    this.api.setUrl(
      `${this.$service[this.service.key]}/v1/en/console/model/${
        this.model.key
      }/${this.modelId}/delete`
    );
    this.api.setCallbackCompleted(() => {
      this.$router.push({
        name: "PageServiceModelBrowse",
        params: {
          serviceKey: this.model.serviceKey,
          modelKey: this.model.key,
        },
      });
      this.$store.dispatch("showMessage", "Deleted successfully.");
    });
    //EOC
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>